<div class="flex-row space-between align-center">
  <div class="link-title" i18n>Links</div>
  <mat-icon *ngIf="editable" class="va-middle add-button" (click)="addButtonClicked()">add</mat-icon>
</div>
<div [formGroup]="orderForm" class="links">
  <div formArrayName="items" *ngFor="let item of formControls.controls; let i = index">
    <div class="link-row card-container" [formGroupName]="i">
      <ng-container *ngIf="!editable">
        <p class="title">{{ item.get('title')?.value }}</p>
        <a [href]="item.get('url')?.value" class="url" target="_blank">{{ item.get('url')?.value }}</a>
      </ng-container>

      <ng-container *ngIf="editable">
        <div class="flex-row">
          <div class="flex-col flex-4">
            <mat-form-field appearance="fill">
              <input matInput formControlName="title" placeholder="Title" i18n-placeholder (input)="inputChange()" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <input matInput formControlName="url" placeholder="URL" i18n-placeholder (input)="inputChange()" />
            </mat-form-field>
          </div>
          <div class="flex-auto"></div>
          <div clas>
            <mat-icon *ngIf="editable" class="va-middle remove-button" (click)="removeButtonCLick(i)">remove</mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="custom-links" style="visibility: hidden; background: red"></div>
</div>