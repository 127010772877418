import { ChangeDetectionStrategy, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';

import { User, UserLinks } from 'src/app/services/hobbyts.service';
import { ProfileService } from 'src/app/core/profile/profile.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'hb-info-links',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatIconModule, FormsModule, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule]
})
export class LinkComponent implements OnInit {
  @Input() public editable!: boolean;
  @Input() public playerData!: User;

  public orderForm!: FormGroup;
  public items!: FormArray;
  public links: UserLinks[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  public get formControls(): FormArray {
    return this.orderForm.get('items') as FormArray;
  }

  public ngOnInit(): void {
    if (this.playerData?.links) {
      this.links = this.playerData?.links;
    }

    this.orderForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });

    if (this.playerData?.links) {
      this.items = this.orderForm.get('items') as FormArray;
      if (this.playerData?.links) {
        for (const link of this.playerData.links) {
          this.items.push(this.createItem(link));
        }
      }
      this.inputChange();
    }
  }

  public addButtonClicked(): void {
    this.items = this.formControls;
    this.items.push(this.createItem(null));
    const className = '.custom-links';
    const element = this.renderer.selectRootElement(className);
    element.scrollIntoView(true);
  }

  public inputChange(): void {
    const itemsToSend = this.items.value.filter(
      (item: any) => item.title && this.sanitizer.bypassSecurityTrustUrl(item.url)
    );
    const existing = this.profileService.profileObj$.getValue();

    this.profileService.profileObj$.next({
      ...existing,
      links: itemsToSend
    });
  }

  public removeButtonCLick(index: number): void {
    this.items.removeAt(index);
    this.inputChange();
  }

  private createItem(value: any): FormGroup {
    return this.formBuilder.group({
      title: [value?.title],
      url: [value?.url]
    });
  }
}
